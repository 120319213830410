import React from 'react'
//import { Button } from 'reakit'
import styled from "@emotion/styled";
import { Link, navigate } from 'gatsby'
//import { handleLogin, logout, isLoggedIn } from "../../server/auth"
import Auth0Lock from 'auth0-lock';
import config from "../../utils/config";
import {createCustomer} from "../stripe/functions";
import {response} from "express";
import { FormattedMessage } from 'react-intl';

const authLogo = require('../../../assets/images/V7_000_Logo.png');
var moment = require('moment');


const NextButton = styled.div`
cursor:pointer;
width:100%;
margin-left:auto;
margin-right:auto;
margin-bottom:10px;
margin-top:20px;  
height:45px !important;
border-radius:2px;
display:flex;
background:#daac00;
letter-spacing:1px;
font-weight:bold;
font-size:19px;
 justify-content:center;
 transition:all .7s ease;
 
 :hover{
 letter-spacing:0px;
 }
`

const A = styled.div`
display:flex;
justify-content:center;
align-items:center;
color:white;
`
let options = {
  container: 'pop-lock-container',

  theme: {
    logo: authLogo,
    primaryColor: '#188cec'

  },
  languageDictionary: {
    title: "Here you can login or sign up",
    emailInputPlaceholder: "Email adress",
    usernameInputPlaceholder: "Full name",
    passwordInputPlaceholder: "Password",
  },
  rememberLastLogin: true,
  socialButtonStyle: 'big',


};





// render

const Div = styled.div`
 width:40%;
 z-index:1 !important;
 margin-top:40px;
 margin-left:auto;
 margin-right:auto;
 font-family: "Theano Didot",Regular !important;
 border:1px solid lightgrey;
border-radius: 2px;
box-shadow: 1px 2px 4px rgba(0,0,0,.1);

padding-left: 20px;
padding-bottom: 20px;
padding-right: 20px;
padding-top: 20px;

   @media screen and (max-width: 1200px) {
width:80%;}
    
    
    @media screen and (max-width: 760px) {
width:100%;}
 
 .auth0-lock-header{
  display:none !important;
} 


.auth0-loading{
left:48.7% !important;
  
}

.auth0-lock-tabs{
font-family: "Theano Didot",Regular !important; 
text-transform:uppercase !important; 
font-size:14px !important;
}


.auth0-lock-submit{
font-family: "Theano Didot",Regular !important; 
text-transform:uppercase !important;
border-radius:2px !important; 
height:45px !important;

}

.auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit{
line-height:22px !important;
}
.auth0-lock.auth0-lock .auth0-lock-submit:hover:not([disabled]) span{
transform:none;
}

.auth0-lock-form{
  margin-left:none;
  margin-right:none;
  
}
 
 .auth0-lock.auth0-lock .auth0-lock-header-bg{
 background:white !important;
 filter:none;

 }
 
 .auth0-lock.auth0-lock.auth0-lock-opened-in-frame{
 width:100% !important;
 }
 
 .auth0-lock.auth0-lock .auth0-lock-widget {
 width:100% !important;
border:none !important;
 
 
 }
 
 .auth0-lock.auth0-lock .auth0-lock-close-button, .auth0-lock.auth0-lock .auth0-lock-back-button{
 height:25px;
 width:25px;
 color:rgb(68,68,68);
 z-index:9999999;
 
 
 }
 
 .auth0-lock.auth0-lock .auth0-lock-header{
 padding-top:50px;
 }
 .auth0-lock-header-bg-blur{
 background-color:white !important;
 filter: none !important;
 background-image:none !important ;
 }
 
.auth0-lock-cred-pane-internal-wrapper{
height:auto !important;
 
 }
 
 .modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.auth0-lock-social-button{
border-radius:2px !important;
}

.auth0-lock-social-button[data-provider="google-oauth2"]{
background:rgba(68,68,68,.5) !important;



}

.auth0-lock-social-button[data-provider^="google"] .auth0-lock-social-button-icon{


}

.auth0-lock.auth0-lock .auth0-lock-social-button[data-provider="google-oauth2"].auth0-lock-social-big-button .auth0-lock-social-button-text{
color:white !important;

:hover{
color:white !important;
background:rgba(68,68,68,.7) !important;
}


}




`

let lock;
export class LoginLock extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }

  }



  componentDidMount(){
    // initialize
    lock = new Auth0Lock(

      'YY3uCnLuNwtYJ8w3S6Wdq5Z8bH7mD2GK',
      'dev-uv101.eu.auth0.com',
      options,
      {
        auth: {
          redirect: false
        }
      }



    );


    //
    lock.on("authenticated", function(authResult) {
      lock.getUserInfo(authResult.accessToken, function(error, profile) {
        if (error) {
          // Handle error
          return;
        }

        createCustomer(profile.email, profile.name, profile.user_id ).catch((err) => { // Error response
          //console.log(err);
        });

        let now = moment();
        let expires_at = now.add(3599, 'seconds');
        localStorage.setItem("accessToken", authResult.accessToken);
        localStorage.setItem("profile", JSON.stringify(profile));
        localStorage.setItem("expires_at", expires_at.toISOString());


        navigate('/payment')      });
    })
    lock.show();


  }



  componentWillUnmount(){
    //
    lock.hide();


  }

  render() {

    return (


      <Div id='pop-lock-container' >

      </Div>


    )
  }
}
